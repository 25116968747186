import { User } from "@privy-io/react-auth";

export const getEmailAndNameFromPrivyUser = (user: User) => {
  let email: string | null = null,
    name: string | null = null;

  if (user.discord) {
    email = user.discord.email;
    name = user.discord.username;
  } else if (user.github) {
    email = user.github.email;
    if (!email) {
      email = localStorage.getItem("email") || "";
    }
    name = user.github.name || user.github.username;
  } else if (user.google) {
    email = user.google.email;
    name = user.google.name;
  } else if (user.email) {
    email = user.email.address;
    name = email?.split("@")[0] ?? "";
  }

  return { email, name };
};

interface GithubEmailsResponseItem {
  email: string;
  primary: boolean;
  verified: boolean;
  visibility: string;
}

const GET_USER_DETAILS_URL = {
  github: "https://api.github.com/user/emails",
};

const getHeadersForUserDetails = (
  provider: keyof typeof GET_USER_DETAILS_URL,
  token: string
) => {
  return {
    github: {
      Accept: "application/vnd.github+json",
      Authorization: `Bearer ${token}`,
      "X-GitHub-Api-Version": "2022-11-28",
    },
  }[provider];
};

export const getUserEmailFromOAuth = async (
  token: string,
  provider: keyof typeof GET_USER_DETAILS_URL
) => {
  switch (provider) {
    case "github":
      try {
        const response = await fetch(GET_USER_DETAILS_URL.github, {
          headers: getHeadersForUserDetails(provider, token),
        });
        const json = (await response.json()) as GithubEmailsResponseItem[];
        if (json.length > 0) {
          const email = json[0].email;
          return email;
        }
      } catch (error) {
        console.error(`failed to get user details - ${provider}: `, error);
        return "";
      }
  }
  return "";
};
