"use client";

import ModalStyles from "@/components/modals/modal.module.scss";
import { Modal } from "@spheron/ui-library";
import ModalWrapper from "./modal-wrapper";
import LineLoader from "../line-loader";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const LoginModal = ({ isOpen, setIsOpen }: IProps) => {
  return (
    <ModalWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={`${ModalStyles.modal__inner__content} !w-[455px]`}>
        <Modal
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-6 px-4 pb-2 text-base-para-text-color dark:text-dark-base-para-text-color items-center w-full text-center">
              <div className="flex flex-col justify-center items-center gap-6">
                <p className="text-base-heading-text-color dark:text-dark-base-heading-text-color text-base">
                  Please wait while we set up your account.
                </p>
                <LineLoader />
              </div>
            </div>
          }
          headerContent={<></>}
          primaryButtonContent="Withdraw"
          secondaryButtonContent="Cancel"
          crossIcon={false}
        />
      </div>
    </ModalWrapper>
  );
};

export default LoginModal;
