"use client";

import { Dialog, DialogPanel } from "@headlessui/react";
import ModalStyles from "@/components/modals/modal.module.scss";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: JSX.Element;
}
const ModalWrapper = ({ isOpen, setIsOpen, children }: IProps) => {
  const handleClose = (e: any) => {
    if (e.target.classList && e.target.classList.contains("modal")) {
      setIsOpen(false);
    }
  };
  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative focus:outline-none"
      onClose={() => {}}
    >
      <div
        className={`${ModalStyles.modal__outer__con} bg-black bg-opacity-25 dark:bg-opacity-75
       modal fixed inset-0 overflow-y-auto`}
        onClick={(e) => {
          handleClose(e);
        }}
        role="presentation"
      >
        <DialogPanel transition>{children}</DialogPanel>
      </div>
    </Dialog>
  );
};

export default ModalWrapper;
