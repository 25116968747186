import React, { ReactElement } from "react";

// .loader__line {
//   width: 200px;
//   height: 3px;
//   position: relative;
//   overflow: hidden;
//   background-color: #ddd;
//   -webkit-border-radius: 20px;
//   -moz-border-radius: 20px;
//   border-radius: 20px;
// }

// .loader__line:before {
//   content: '';
//   position: absolute;
//   left: -50%;
//   height: 3px;
//   width: 40%;
//   background-color: #4977f9;
//   -webkit-animation: lineAnim 1s linear infinite;
//   -moz-animation: lineAnim 1s linear infinite;
//   animation: lineAnim 1s linear infinite;
//   -webkit-border-radius: 20px;
//   -moz-border-radius: 20px;
//   border-radius: 20px;
// }

// @keyframes lineAnim {
// 0% {
//   left: -40%;
// }
// 50% {
//   left: 20%;
//   width: 80%;
// }
// 100% {
//   left: 100%;
//   width: 100%;
// }
// }

interface IProps {
  className?: string;
}

const LineLoader = ({ className = "" }: IProps) => {
  return (
    <div
      className={`before:animate-line-loader w-52 h-[3px] relative overflow-hidden bg-[#dddddd] rounded-2xl before:absolute before:-left-1/2 before:h-[3px] before:w-2/5 before:bg-action-neutral-default before:rounded-2xl ${
        !!className ? className : ""
      }`}
    />
  );
};

export default LineLoader;
